import type { Attribute } from '@strapi/strapi';
import { STRAPI_EXCLUDE_FROM_SITEMAP_TAG } from 'constants/strapi';

export const isPageExcludedFromSiteMap = (
  tagsList: Attribute.JsonValue | undefined
) => {
  if (tagsList && Array.isArray(tagsList)) {
    return tagsList.includes(STRAPI_EXCLUDE_FROM_SITEMAP_TAG);
  }

  return false;
};
