import { ArrowUpRightIcon } from '@popsure/dirty-swan';
import { PrismicLink, PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';
import { AnnouncementBarDocument } from 'types.generated';

import styles from './AnnouncementBar.module.scss';

interface AnnouncementBarProps {
  document: AnnouncementBarDocument;
}

export const AnnouncementBar = ({ document }: AnnouncementBarProps) => (
  <section
    className={classNames(
      'd-flex f-wrap bg-purple-100 p16 ai-center jc-center ta-center p-p',
      styles.message
    )}
  >
    {document.data.message && <PrismicRichText field={document.data.message} />}
    {document.data.cta_link && (
      <PrismicLink
        field={document.data.cta_link}
        className="p-a fw-bold tc-grey-900 d-inline-flex ai-center ml8"
      >
        {document.data.cta_text || 'Click here'}
        <ArrowUpRightIcon size={16} className="ml8" />
      </PrismicLink>
    )}
  </section>
);
